@font-face {
  font-family: fontang;
  font-weight: normal;
  src: url('./fonts/Sarabun-Medium.ttf');
}

* {
  font-family: fontang;
}

#root {
  height: 100vh;
}

body {
  background-color: #a0aaab;
  line-height: 1.1;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.uafooter {
  display: block !important;
}

.uacssadd {
  float: left !important;
}

.frightimp {
  float: right !important;
}

.uaboxcss {
  overflow-y: auto;
  max-height: 80vh;
}

.rgboxcss {
  overflow-y: auto;
  max-height: 65vh;
}

/* status */
.stvyellow {
  width: 10px;
  height: 25px;
  background-color: #ffff00;
  display: inline-block;
  border-style: inset;
  border-width: 0.25px;
  border-color: black;
}

.stvpink {
  width: 10px;
  height: 25px;
  background-color: #ff1ac6;
  display: inline-block;
  border-style: inset;
  border-width: 0.25px;
  border-color: black;
}

.stvorange {
  width: 10px;
  height: 25px;
  background-color: #ff9900;
  display: inline-block;
  border-style: inset;
  border-width: 0.25px;
  border-color: black;
}

.stvpurple {
  width: 10px;
  height: 25px;
  background-color: #6556a1;
  display: inline-block;
  border-style: inset;
  border-width: 0.25px;
  border-color: black;
}

.stvblue {
  width: 10px;
  height: 25px;
  background-color: #65cbe9;
  display: inline-block;
  border-style: inset;
  border-width: 0.25px;
  border-color: black;
}

.stvgreen {
  width: 10px;
  height: 25px;
  background-color: #00e000;
  display: inline-block;
  border-style: inset;
  border-width: 0.25px;
  border-color: black;
}

.stvred {
  width: 10px;
  height: 25px;
  background-color: #e60000;
  display: inline-block;
  border-style: inset;
  border-width: 0.25px;
  border-color: black;
}

.stvgray {
  width: 10px;
  height: 25px;
  background-color: white;
  display: inline-block;
  border-style: inset;
  border-width: 0.25px;
  border-color: black;
}

/* status */
.borderleftboP {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.fontred {
  color: red;
}

.whitebg {
  background-color: #fff;
}

.windowfit {
  min-height: auto;
  height: 90%;
}

.cardfull {
  min-height: 100%;
}

.Fontsmall {
  font-size: 13px;
}

.Fontsmall12 {
  font-size: 12px;
}

.Fontsmall10 {
  font-size: 10px;
}

.Fontsmall8 {
  font-size: 8px;
}

.smallbtintable {
  padding: 0;
  padding-left: 8px;
  padding-right: 8px;
}

.smallbtintable2 {
  padding: 0;
  padding-left: 8px;
  padding-right: 8px;
  height: 23.6px;
}

.mleft {
  padding-left: 5px;
}

.mright {
  padding-right: 5px;
}

.mmleft {
  margin-left: 5px;
}

.mmright {
  margin-right: 5px;
}

.mmleft30top10 {
  margin-left: 30px;
  margin-top: 10px;
}

.noleftright {
  padding-left: 0;
  padding-right: 0;
}

.nolefg15 {
  padding-left: 13px;
  padding-right: 13px;
}

.max100p {
  max-width: 100% !important;
}

.uaitemspop {
  padding: 0 0 !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbcitemspop {
  padding: 0 0 !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbcitempopspan,
.rbcitempopspan span {
  max-width: 160px !important;
  width: 160px !important;
}

.uaitempopspan,
.uaitempopspan span {
  max-width: 60px !important;
  width: 60px !important;
}

.uaitempopspan span {
  display: block;
  padding: 0.375rem 0.25rem !important;
}

.custom-selectggg {
  height: 23.59px;
}

/*Header & Footer*/
.Topperbar {
  margin-bottom: 15px;
  padding: 0.25rem 0.5rem;
  background-color: #000000;
}

/*Header & Footer*/
/*Main*/
#rbc_t_id::placeholder {
  color: black;
  opacity: 1;
}

#ua_t_id::placeholder {
  color: black;
  opacity: 1;
}

.main_home_icon {
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
  vertical-align: middle;
  padding: 5px;
  font-weight: bold;
}

.main_home_icon a {
  color: #212529;
}

.main_home_icon a:visited {
  color: none;
}

.main_home_icon a div {
  margin-top: 6px;
}

.main_home_icon a svg {
  height: 100%;
}

.thisth {
  background-color: #ddd;
}

.statustd {
  width: 100px;
}

.workplacefull {
  min-height: 60vh;
}

.worksection {
  min-height: 60vh;
}

/*Main*/
/* Setting */
#SettingMainComLeft {
  overflow-y: scroll;
  scrollbar-width: none;
}

#SettingMainComLeft::-webkit-scrollbar {
  display: none;
}

#settingselectcom div {
  padding: 12px 5px 12px 5px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  background-color: #c9ced6;
  margin: 2px 15px 2px 15px;
}

#settingselectcom div:hover {
  padding: 12px 5px 12px 5px;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 0.25rem;
  background-color: #8f9db3;
  margin: 2px 15px 2px 20px;
}

.selectedsettingcom {
  padding: 12px 5px 12px 5px !important;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 0.25rem;
  background-color: #8f9db3 !important;
  margin: 2px 15px 2px 20px !important;
}

.settingworkplacefull {
  min-height: 90vh;
}

.settingselecttable {
  border-right: 2px solid #343a40;
  min-height: 70px !important;
  max-height: 89.5vh;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 0 !important;
  transition-duration: 1s;
}

#SettingMainComRight {
  max-height: 89.5vh;
}

/* Setting */
/*LAB*/
.cardfull-lab {
  min-height: 100%;
}

.cardfull-lab-body {
  padding: 0.1rem 1.25rem 0rem 1.25rem;
}

.labselecttable {
  border: 2px;
  border-style: solid;
  border-color: #343a40;
  min-height: 70px !important;
  height: 65vh;
  max-height: 65vh;
  /*overflow: auto!important;*/
  overflow-y: scroll;
  overflow-x: scroll;
  padding: 0 !important;
  transition-duration: 1s;
}

.labdetail {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: max-content auto;
  padding-right: 0px;
  padding-left: 0px;
  height: auto !important;
  min-height: 70px !important;
  height: 65vh !important;
  max-height: 65vh !important;
  transition-duration: 1s;
}

.labdetailsecheader {
  background-color: rgba(0, 0, 0, 0.05);
  border: 2px;
  border-style: solid;
  border-color: #343a40;
  margin-left: 1px;
  width: 100%;
  padding: 4px 0;
  line-height: 25px;
}

.labdetailcontainerlg {
  border: 2px;
  border-style: solid;
  border-color: #343a40;
  margin-left: 1px;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: auto !important;
  padding: 0 !important;
}

.labdetailcontainerlg_modal {
  border: 2px;
  border-style: solid;
  border-color: #343a40;
  margin-left: 1px;
  width: 100%;
  overflow: auto !important;
  padding: 0 !important;
}

.labdetailcontainerlg_historymodal {
  border: 2px;
  border-style: solid;
  border-color: #343a40;
  margin-left: 1px;
  width: 100%;
  height: 75vh;
  max-height: 75vh;
  overflow: auto !important;
  padding: 0 !important;
}

.labdetailsecheaderpop {
  background-color: rgba(0, 0, 0, 0.05);
  border: 2px;
  border-style: solid;
  border-color: #343a40;
  margin-left: 1px;
  width: 100%;
  padding: 0.75rem 0;
  line-height: 25px;
}

.labdetailcontainerlg div {
  padding: 0 !important;
}

.labdetailcontainerlg_modal div {
  padding: 0 !important;
}

.labdetailcontainerlg_historymodal div {
  padding: 0 !important;
}

.custom-switch {
  left: 0;
  padding: 0;
  height: 100%;
}

.custom-control-label {
  width: -webkit-fill-available;
  height: 100%;
  container-type: inline-size;
}

.custom-switch .custom-control-label::before {
  position: relative;
  width: 70%;
  top: 5px;
  height: 1.5em;
  border-radius: 0.6rem;
  border: 1px solid #ced4da !important;
  cursor: pointer;
  left: 15%;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.custom-switch .custom-control-label::after {
  width: 1em;
  height: 1em;
  left: 17%;
  cursor: pointer;
  top: 9px;
  z-index: 0;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(calc((65cqw - 100%)));
}

#lgspanboy {
  border: none;
  background-color: white;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

#lgspanhos {
  border: none;
  background-color: white;
}

#id-s-d,
#id-s-m,
#id-s-y,
#id-e-d,
#id-e-m,
#id-e-y {
  padding: 0.375rem 0 0.375rem 0.375rem !important;
}

/* .calen .form-control{flex-direction: row;} */
#sTime {
  font-family: 'fontang';
  position: inherit;
}

#eTime {
  font-family: 'fontang';
  position: inherit;
}

.sDate .MuiOutlinedInput-root {
  position: initial;
  border-radius: 0 !important;
}

.eDate .MuiOutlinedInput-root {
  position: initial;
  border-radius: 0 4px 4px 0 !important;
}

#visitTypeLabel {
  border: none;
  background-color: white;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.zoom-in {
  cursor: zoom-in;
}

.tablenewclass .table {
  margin-bottom: 0;
}

.tablenewclass .table th {
  padding-top: 4px !important;
  padding-bottom: 0;
}

.tablenewclass .table td {
  padding-top: 0;
  padding-bottom: 0;
}

.tablenewclass .table td {
  vertical-align: middle !important;
}

/*Left Custom Head*/
#TableML th {
  padding: 0 !important;
  padding-top: 5px !important;
}

.tinCell {
  padding: 0 !important;
  padding-left: 5px !important;
}

.lis_status_ch {
  padding-left: 0 !important;
}

.HTCell_lis_status_ch {
  padding-left: 0 !important;
}

/*Left Custom Head*/
.stat_a,
.stat_A {
  background-color: #a0522d;
}

.stat_s,
.stat_S {
  background-color: #fa8128;
}

.alerttr {
  background-color: #c41919;
  cursor: pointer;
}

.alerttr:hover {
  background-color: #9b1414 !important;
}

.alertcell {
  background-color: #c41919;
  cursor: pointer;
}

.alertcell:hover {
  background-color: #9b1414 !important;
}

.trlabselect {
  background-color: #8a8a8a !important;
}

.trlabselect2 {
  background-color: #8a8a8a !important;
}

.nwidth10I {
  min-width: 10px;
  width: 10px;
}

.nwidth10 {
  min-width: 10px;
}

.nwidth50 {
  min-width: 50px;
  width: 50px;
}

.nwidth80 {
  min-width: 80px;
  width: 80px;
}

.nwidth100 {
  min-width: 100px;
  width: 100px;
}

.nwidth200 {
  min-width: 200px;
  width: 200px;
}

.nwidth280 {
  min-width: 280px;
  width: 280px;
}

.nwidth400 {
  min-width: 250px;
  width: 400px;
}

.tablelab2right .table {
  overflow: auto !important;
}

.tablelab2right .table th,
.tablelab2right .table td {
  padding: 1.5px 2.5px;
}

.tablelab2right .table td {
  vertical-align: middle !important;
}

.checkbox-menu li label {
  display: block;
  padding: 3px 10px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  margin: 0;
  transition: background-color 0.4s ease;
}

.checkbox-menu li input {
  margin: 0px 5px;
  top: 2px;
  position: relative;
}

.checkbox-menu li.active label {
  background-color: #cbcbff;
  font-weight: bold;
}

.checkbox-menu li label:hover,
.checkbox-menu li label:focus {
  background-color: #f5f5f5;
}

.checkbox-menu li.active label:hover,
.checkbox-menu li.active label:focus {
  background-color: #b8b8ff;
}

.dropdownlab-menu {
  max-height: auto !important;
  overflow-y: hidden !important;
}

.dropdown-lg-nolimit {
  overflow-y: scroll !important;
}

/*LAB*/
/*NewNote*/
.noterepa,
.internal_noterepa,
.flag_noterepa {
  padding: 0 0.35rem 0 0.35rem;
}

#notedetail,
#internal_notedetail,
#flag_notedetail {
  overflow: auto;
}

#notedetail::-webkit-scrollbar,
#internal_notedetail::-webkit-scrollbar,
#flag_notedetail::-webkit-scrollbar {
  width: 5px;
}

#notedetail::-webkit-scrollbar-thumb,
#internal_notedetail::-webkit-scrollbar-thumb,
#flag_notedetail::-webkit-scrollbar-thumb {
  background: #888;
}

#notedetail::-webkit-scrollbar-thumb:hover,
#internal_notedetail::-webkit-scrollbar-thumb:hover,
#flag_notedetail::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.classhadnote {
  background-color: #ffcc33;
}

.classselectednote {
  text-decoration: underline;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/*NewNote*/

/*REPORT PUNCH*/
/* .reportworksection{min-height: 65vh;} */
.reportworksection2 {
  min-height: 75vh;
}

.reportlabselecttable {
  border: 2px;
  border-style: solid;
  border-color: #343a40;
  height: 73vh;
  max-height: 73vh;
  overflow: auto !important;
  padding: 0 !important;
  transition-duration: 1s;
}

.reportlabdetail {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: max-content auto;
  padding-right: 0px;
  padding-left: 0px;
  height: auto !important;
  max-height: 75vh !important;
  transition-duration: 1s;
}

.greyTransparentBlock {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: gray;
  opacity: 0.5;
  z-index: 2;
  text-align: center;
}

/*REPORT PUNCH*/

/*CONTEXT MENU*/
.contextMenu {
  border-radius: 4px;
  box-sizing: border-box;
  position: absolute;
  width: 150px;
  background-color: #383838;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1);
}

.contextMenu ul {
  list-style-type: none;
  box-sizing: border-box;
  margin: 0;
  padding: 5px;
}

.contextMenu ul li {
  padding: 9px 6px;
  border-radius: 4px;
  color: white;
}

.contextMenu ul li:hover {
  cursor: pointer;
  background-color: #4b4b4b;
}

/*CONTEXT MENU*/

/*CREATEORDER*/
.cardfull-order {
  min-height: 100%;
}

.cardfull-order-body {
  max-height: 58vh;
  overflow-y: scroll;
}

.worksectionorder {
  min-height: 54vh;
}

/*CREATEORDER*/
/*HISMAP*/
.cardfull-HIS {
  min-height: 100%;
}

.cardfull-HIS-body {
  padding: 0.1rem 1.25rem 0rem 1.25rem;
}

.hisselecttable {
  border: 2px;
  border-style: solid;
  border-color: #343a40;
  min-height: 70px !important;
  height: 88vh;
  max-height: 88vh;
  overflow: auto !important;
  padding: 0 !important;
  transition-duration: 1s;
}

.hisdetail {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: max-content max-content;
  padding-right: 0px;
  padding-left: 0px;
  height: auto !important;
  max-height: 88vh !important;
  transition-duration: 1s;
}

.hisdetailsecheader {
  background-color: rgba(0, 0, 0, 0.05);
  border: 2px;
  border-style: solid;
  border-color: #343a40;
  margin-left: 1px;
  width: 100%;
  padding: 4px 0;
}

.hisdetailsecheader div {
  padding-left: 1px;
  padding-right: 1px;
}

/*HISMAP*/
/*Item*/
.cardfull-mitem {
  min-height: 100%;
}

.cardfull-mitem-body {
  padding: 0.1rem 1.25rem 0rem 1.25rem;
}

.mitemlabselecttable {
  border: 2px;
  border-style: solid;
  border-color: #343a40;
  min-height: 70px !important;
  height: 85vh;
  max-height: 85vh;
  overflow: auto !important;
  padding: 0 !important;
}

.mitemlabdetail {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: max-content auto;
  padding-right: 0px;
  padding-left: 0px;
  height: auto !important;
  max-height: 75vh !important;
}

.mitemdetailcontainerlg {
  border: 2px;
  border-style: solid;
  border-color: #343a40;
  margin-left: 1px;
  width: 100%;
  overflow: auto !important;
  padding: 0 !important;
  min-height: 70px !important;
  height: 85vh;
  max-height: 85vh;
}

.mitemdetailcontainerlg div {
  padding: 0 !important;
}

.mtableitem2right .table {
  overflow: auto !important;
}

.mtableitem2right .table th,
.mtableitem2right .table td {
  padding: 1.5px 2.5px;
}

.mtableitem2right .table td {
  vertical-align: middle !important;
}

.custom-map-modal .modal-dialog {
  width: 100%;
  height: 90%;
  max-width: 90%;
}

.custom-map-modal .modal-content {
  height: auto;
  min-height: 100%;
}

/*Item*/
/*Account*/
.accsselecttable {
  border: 2px;
  border-style: solid;
  border-color: #343a40;
  min-height: 70px !important;
  height: 80vh;
  max-height: 80vh;
  overflow: auto !important;
  padding: 0 !important;
}

.accsde {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: max-content auto;
  padding-right: 0px;
  padding-left: 0px;
  height: auto !important;
  max-height: 80vh !important;
}

.accsheader {
  background-color: rgba(0, 0, 0, 0.05);
  border: 2px;
  border-style: solid;
  border-color: #343a40;
  margin-left: 1px;
  width: 100%;
  padding: 4px 0;
  line-height: 25px;
}

.accscontainerlg {
  border: 2px;
  border-style: solid;
  border-color: #343a40;
  margin-left: 1px;
  width: 100%;
  overflow: auto !important;
  padding: 0 !important;
  min-height: 70px !important;
  height: auto;
}

/*Account*/

/*Status*/
.dotred,
.dotyellow,
.dotpink,
.dotorange,
.dotgreen,
.dotblue,
.dotpurple {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  margin-top: 3px;
}

.dotredsmall,
.dotyellowsmall,
.dotpinksmall,
.dotorangesmall,
.dotgreensmall,
.dotbluesmall,
.dotpurplesmall {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-top: 3px;
}

/*Red dot*/
.dotred,
.dotredsmall {
  background-color: #e60000;
  -webkit-animation: rdoting 2s infinite alternate;
  -moz-animation: rdoting 2s infinite alternate;
  -ms-animation: rdoting 2s infinite alternate;
  -o-animation: rdoting 2s infinite alternate;
  animation: rdoting 2s infinite alternate;
}

@-webkit-keyframes rdoting {
  from {
    background-color: #e60000;
  }

  to {
    background-color: #ffe5e7;
  }
}

@-moz-keyframes rdoting {
  from {
    background-color: #e60000;
  }

  to {
    background-color: #ffe5e7;
  }
}

@-ms-keyframes rdoting {
  from {
    background-color: #e60000;
  }

  to {
    background-color: #ffe5e7;
  }
}

@-o-keyframes rdoting {
  from {
    background-color: #e60000;
  }

  to {
    background-color: #ffe5e7;
  }
}

@keyframes rdoting {
  from {
    background-color: #e60000;
  }

  to {
    background-color: #ffe5e7;
  }
}

/*Red dot*/
/*Yellow dot*/
.dotyellow,
.dotyellowsmall {
  background-color: #ffff00;
}

/*Yellow dot*/
/*Pink dot*/
.dotpink,
.dotpinksmall {
  background-color: #ff1ac6;
}

/*Pink dot*/
/*Orange dot*/
.dotorange,
.dotorangesmall {
  background-color: #ff9900;
}

/*Orange dot*/
/*Purple dot*/
.dotpurple,
.dotpurplesmall {
  background-color: #6556a1;
}

/*Purple dot*/
/*Blue dot*/
.dotblue,
.dotbluesmall {
  background-color: #65cbe9;
}

/*Blue dot*/
/*Green dot*/
.dotgreen,
.dotgreensmall {
  background-color: #00e000;
}

/*Status*/

.colorred {
  background-color: rgba(0, 0, 0, 0.05);
}

.bgcolorgray {
  background-color: #cfcfcf;
}

.bgcolorgray2 {
  background-color: #ededed;
}

.stdinputg {
  min-height: 40px;
  height: 40px;
}

/*Button*/
.justpoint {
  cursor: pointer;
}

.btngotnew {
  font-size: 17px;
  min-height: 40px;
  min-width: 140px;
  word-break: break-word;
  /* max-width: 120px; */
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  letter-spacing: 1px;
}

.btngotnew150 {
  font-size: 15px;
  height: 40px;
  width: 150px;
  max-width: 150px;
  cursor: pointer;
  display: inline-block;
}

.btspaceright {
  margin-bottom: 10px;
}

.btspaceright:not(:first-child) {
  margin-left: 5px;
}

.button-glow {
  animation: glowing 2000ms infinite;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 -10px #2dba44;
    font-size: 15px;
  }

  40% {
    box-shadow: 0 0 25px #2dba44;
    font-size: 17px;
  }

  60% {
    box-shadow: 0 0 25px #2dba44;
    font-size: 17px;
  }

  100% {
    box-shadow: 0 0 -10px #2dba44;
    font-size: 15px;
  }
}

.reject-glow {
  animation: rejectglowing 2000ms infinite;
}

@keyframes rejectglowing {
  0% {
    box-shadow: 0 0 -10px #bf2121;
  }

  40% {
    box-shadow: 0 0 25px #bf2121;
  }

  60% {
    box-shadow: 0 0 25px #bf2121;
  }

  100% {
    box-shadow: 0 0 -10px #bf2121;
  }
}

/*Button*/
/*NavHeader*/
.navposition {
  position: fixed !important;
  width: 100%;
  z-index: 9000 !important;
}

.logonavbar {
  width: 120px;
  /*margin-left: 10px;margin-right: 23px;*/
}

.newnavclasss {
  padding: 0 !important;
}

.programname {
  vertical-align: middle;
  font-family: fontsang;
  letter-spacing: 0.8px;
}

.stickbell {
  top: -5px !important;
}

.hidecontent .dropdown-toggle::after {
  content: none;
}

.iconalertla {
  padding: 0.375rem 0 !important;
  margin-right: 5px;
  margin-left: 5px;
}

.iconalertla::before {
  display: none !important;
  content: none !important;
}

.iconalertla::after {
  display: none !important;
  content: none !important;
}

.blueicon {
  color: #007bff;
}

/*NavHeader*/
/*Footer*/
.footercopyright {
  background-color: #000 !important;
  color: #fff !important;
  width: 100%;
  margin-top: 10px;
}

.footersize {
  padding: 15px;
}

footer {
  clear: both;
  position: relative !important;
  padding: 15px;
  bottom: 0 !important;
}

/*Footer*/
/*Dropdown*/
.dropbtn {
  color: white;
  padding: 16px;
  font-size: 13px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 53px;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9000 !important;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-menu {
  max-height: 600px;
  overflow-y: scroll;
}

/*Dropdown*/

@media only screen and (max-width: 1028px) {
  /* body {
    font-size: 12px;
  } */
}

@media only screen and (min-width: 992px) {
  .Topperbar {
    height: 5%;
  }
}

@media only screen and (max-width: 767px) {
  .workplacefull {
    min-height: 70vh;
  }
  .worksection {
    min-height: 522px;
  }
  .smallscreen {
    display: none;
  }
  .normalscreen {
    display: block;
  }
  .labdetail {
    display: block;
    height: auto !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 5px;
  }

  .labdetailsecheader {
    background-color: rgba(0, 0, 0, 0.05);
    border: 2px;
    border-style: solid;
    border-color: #343a40;
    margin-left: 1px;
    width: 100%;
    padding: 0.75rem 0;
    height: auto;
  }

  .labdetailcontainerlg {
    border: 2px;
    border-style: solid;
    border-color: #343a40;
    margin-left: 1px;
    width: 100%;
    min-height: 448.5px !important;
    height: 60vh;
    overflow: auto !important;
    padding: 0 !important;
  }

  .labselecttable {
    border: 2px;
    border-style: solid;
    border-color: #343a40;
    min-height: 520px !important;
    /*overflow: auto!important;*/
    overflow-y: scroll;
    overflow-x: scroll;
    padding: 0 !important;
  }

  #sTime {
    margin-left: 0;
    border-right: 1px solid gray;
  }

  #eTime {
    margin-left: 0;
    border-right: 1px solid gray;
  }

  .accsselecttable {
    border: 2px;
    border-style: solid;
    border-color: #343a40;
    min-height: 520px !important;
    overflow: auto !important;
    padding: 0 !important;
  }

  .accsde {
    display: block;
    height: auto !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 5px;
  }

  .accsheader {
    background-color: rgba(0, 0, 0, 0.05);
    border: 2px;
    border-style: solid;
    border-color: #343a40;
    margin-left: 1px;
    width: 100%;
    padding: 0.75rem 0;
    height: auto;
  }

  .accscontainerlg {
    border: 2px;
    border-style: solid;
    border-color: #343a40;
    margin-left: 1px;
    width: 100%;
    min-height: 448.5px !important;
    height: 60vh;
    overflow: auto !important;
    padding: 0 !important;
  }

  .hisdetail {
    display: block;
    height: auto !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 5px;
  }

  .hisselecttable {
    border: 2px;
    border-style: solid;
    border-color: #343a40;
    min-height: 520px !important;
    overflow: auto !important;
    padding: 0 !important;
  }

  .hisdetailsecheader {
    background-color: rgba(0, 0, 0, 0.05);
    border: 2px;
    border-style: solid;
    border-color: #343a40;
    margin-left: 1px;
    width: 100%;
    padding: 0.75rem 0;
    height: auto;
  }

  .mitemlabdetail {
    display: block;
    height: auto !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 5px;
  }

  .mitemdetailcontainerlg {
    border: 2px;
    border-style: solid;
    border-color: #343a40;
    margin-left: 1px;
    width: 100%;
    min-height: 508.5px !important;
    height: 60vh;
    overflow: auto !important;
    padding: 0 !important;
  }

  .mitemlabselecttable {
    border: 2px;
    border-style: solid;
    border-color: #343a40;
    min-height: 520px !important;
    overflow: auto !important;
    padding: 0 !important;
  }
}

@media only screen and (max-height: 768px) {
  .hisselecttable * {
    font-size: 10px !important;
  }

  .hisdetailsecheader * {
    font-size: 10px !important;
  }
}

@media only screen and (max-height: 720px) {
  .hisselecttable * {
    font-size: 8px !important;
  }

  .hisdetailsecheader * {
    font-size: 8px !important;
  }
}

@media only screen and (max-height: 480px) {
  .hisselecttable * {
    font-size: 6px !important;
  }

  .hisdetailsecheader * {
    font-size: 6px !important;
  }

  /*.hisstdinputg{min-height: 10px;height: 10px;}*/
  /*.radhis{line-height: 0.1px;}*/
}

#printPdf {
  position: fixed;
  top: 0px;
  left: 0px;
  display: block;
  padding: 0px;
  border: 0px;
  margin: 0px;
  visibility: hidden;
  opacity: 0;
}

/* 
#BT1_key{font-size: 15px;color: #89cff0;}
#BT4_key{font-size: 15px;color: #b2d2a4;} */

.tableRG {
  border: 2px;
  border-style: solid;
  border-color: #343a40;
  min-height: 40px !important;
  height: 46vh;
  max-height: 46vh;
  /*overflow: auto!important;*/
  overflow-y: scroll;
  overflow-x: scroll;
  padding: 0 !important;
  transition-duration: 1s;
}

.modal-95vw {
  width: 95vw;
  max-width: 95vw;
}

.modal-title-box {
  border: 1px solid #0000ff;
  padding: 4px;
  background-color: #d5ffff;
}

.textarea-note {
  max-height: 180px;
}

.permission-menu {
  background-color: rgba(0, 0, 0, 0.03);
  color: #000000;
  font-size: 18px;
  font-weight: 700;
  padding: 0.5rem 1rem;
  border-bottom: 2px solid rgba(0, 0, 0, 0.125);
}

.permission-menu-list {
  padding: 0;
  padding-left: 10px;
  margin-left: 0.35rem;
  margin-top: 0.5rem;
  border-left: 1px solid #dbdbdb;
}

.scroll-lab-info {
  overflow: auto;
  white-space: nowrap;
}

.scroll-lab-info span {
  position: absolute;
}

.scroll-lab-info::-webkit-scrollbar {
  height: 3px;
}

.scroll-lab-info::-webkit-scrollbar-thumb {
  background: #888;
}

.scroll-lab-info::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.resizer {
  display: inline-block;
  background-color: transparent;
  height: 100%;
  width: 2px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(50%);
  z-index: 1;
  cursor: col-resize;
}

.form-control[readonly].bg-white {
  background-color: #ffffff;
}

.form-control[readonly].bg-red {
  background-color: #ff0000;
}

#swal2-html-container {
  overflow: visible;
}

/* refactor */
.stat-a,
.stat-A {
  background-color: #a0522d;
}

.stat-s,
.stat-S {
  background-color: #fa8128;
}

.critical-alert-tr {
  background-color: #d32f2f;
}

.critical-alert-tr:hover {
  background-color: #f44336 !important;
}
/* refactor */

.sortable-helper {
  z-index: 9999 !important;
}
